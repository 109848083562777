import React from 'react';
import { TbAlertTriangle } from 'react-icons/tb';

const InputErrorMsg = ({ text }: { text: string }) => {
  return (
    <p className="flex gap-1 text-[0.8rem] font-[400] leading-[16px] text-destructive">
      <TbAlertTriangle className="h-[16px] w-[16px] flex-shrink-0" />
      {text} 
    </p>
  );
};

export default InputErrorMsg;
