import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useOtpSend = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>('');
  const [error, setError] = useState<string>('');

  const handleClick = async (
    email: string,
    tenantId: string,
    type: string,
    fromSignup?: boolean,
    wrongEmail?: string
  ) => {
    if (email) {
      setError('');
      setLoading(true);
      try {
        const url = process.env.NEXT_PUBLIC_API_URL;
        const res = await axios.post(`${url}/send-otp`, {
          email,
          tenantId,
          type,
          ...(fromSignup ? { fromSignup } : {}),
          ...(wrongEmail ? { wasWrongEmail: true } : {}),
          ...(wrongEmail ? { wrongEmail } : {}),
        });
        if (res.data) {
          setData(res.data);
        }
      } catch (error: any) {
        const axiosError = error;
        if (axiosError.response) {
          setError(axiosError?.response?.data?.message || 'An error occurred');
        } else if (axiosError.request) {
          setError('Request failed, please try again later');
        } else {
          setError('An error occurred, please try again later');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Email required');
    }
  };

  return {
    loading,
    handleClick,
    data,
    error,
  };
};

export default useOtpSend;
