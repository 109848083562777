import { useEffect, useState } from 'react';

const useMinifyChecker = () => {
  const [minify, setMinify] = useState<boolean | null>(null);

  const setMinifyInSessionStorage = (minifyParams: string | undefined) => {
    if (!minify && minifyParams && typeof window !== 'undefined') {
      window.sessionStorage.setItem('minify', minifyParams);
      setMinify(true);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const minifyValue = window.sessionStorage.getItem('minify');
      if (minifyValue !== null) {
        setMinify(minifyValue === 'true');
      } else {
        setMinify(false);
      }
    }
  }, []);

  return {
    minify,
    setMinifyInSessionStorage,
  };
};

export default useMinifyChecker;
