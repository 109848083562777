import PromoFrame from "@/components/reUsables/PromoFrame";


const LeftSidebar = () => {
  return (
    <div className="mr-3 mt-3 mb-3 h-[calc(100vh-72px)]">
      <PromoFrame />
    </div>
  );
};
export default LeftSidebar;
