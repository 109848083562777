'use client';

import { useEffect } from 'react';
import { motion, useCycle } from 'framer-motion';

import promoImgae1 from '../../../public/images/usm_promo_img_1.png';
import promoImgae2 from '../../../public/images/usm_promo_img_2.png';
import promoImgae3 from '../../../public/images/usm_promo_img_3.png';

const Dot = ({
  index,
  currentImageIndex,
}: {
  index: number;
  currentImageIndex: number;
}) => {
  const isActive = index === currentImageIndex;
  const width = isActive ? '32px' : '8px';
  return (
    <motion.div
      className={`h-2 rounded-full bg-white ${
        isActive ? 'opacity-[64%]' : 'opacity-[24%]'
      }`}
      style={{ width }}
      transition={{ duration: 0.6 }}
      animate={{ width }}
    />
  );
};
const images = [promoImgae1, promoImgae2, promoImgae3];

const PromoFrame = () => {
  const [currentImageIndex, cycleImage] = useCycle(0, 1, 2);

  useEffect(() => {
    const interval = setInterval(cycleImage, 1900);

    return () => clearInterval(interval);
  }, [cycleImage]);

  const texts = [
    {
      heading:
        'Test on Real Hardware with Guided EvaluationTest on Real Hardware with Guided Evaluation',
      description:
        'Step-by-step guided evaluation with ASSIST ME feature for EVK feature exploration. Control input and observe changes on output parameters from real lab instruments via live feed, application streaming and performance data.',
    },
    {
      heading: 'Explore EVKs & Plug-in',
      description:
        'Search among EVKs from multiple Semiconductor Manufactures to find the ready to use lab for your design evaluation. Click on “PLUG IN” to connect to your lab!',
    },
    {
      heading: 'Track changes, Insights and Observations',
      description:
        'Track changes with progress log, find insights and observations on input-output relationships.',
    },
  ];

  return (
    <div className="relative h-full w-full">
      {/* Overlay */}

      {images.map((image, index) => (
        <div
          key={index}
          className={`${index !== currentImageIndex ? 'hidden' : ''} h-full`}
        >
          <motion.img
            src={image.src}
            alt="img"
            height={1000}
            width={1000}
            className="h-full w-full rounded-2xl object-cover"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.9 }}
          />
          {/* Text Overlay */}
          <div className="absolute inset-0 flex flex-col justify-between p-[80px]">
            <motion.div
              key={currentImageIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeOut', duration: 0.9 }}
              className="flex w-full flex-col gap-4"
            >
              <h2
                className={`max-w-[420px] text-[32px] font-normal leading-[36px] tracking-wide text-white`}
                style={{ whiteSpace: 'pre-line' }}
              >
                {index === 0
                  ? 'Setup your\nLab on our cloud!'
                  : texts[index].heading}
              </h2>

              <p className="max-w-[420px] text-sm font-normal tracking-wide text-white opacity-[64%]">
                {texts[index].description}
              </p>
            </motion.div>

            <div className="flex gap-[10px]">
              {images.map((_, dotIndex) => (
                <Dot
                  key={dotIndex}
                  index={dotIndex}
                  currentImageIndex={currentImageIndex}
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PromoFrame;
