import Link from 'next/link';
import { allowedAuth } from '@/interfaces/common';
import { IoLogoLinkedin } from 'react-icons/io5';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import InputErrorMsg from '@/components/reUsables/InputErrorMsg';

const EmailForm = ({
  loading,
  email,
  setEmail,
  handleSubmit,
  fromSignup,
  allowedAuth,
  tenantId,
  errorMsg,
}: {
  loading: boolean;
  email: string;
  setEmail: (email: string) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  fromSignup?: boolean;
  allowedAuth?: allowedAuth;
  tenantId?: string;
  errorMsg?: string;
}) => {
  const isTenantIdExistsOnSessionStorage =
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('tenantId')
      : null;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Label htmlFor="email">
          Email address <span className="text-red-600">*</span>
        </Label>
        <Input
          className={`mt-2 ${errorMsg && "border-red-500"}`}
          type="email"
          id="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder={
            fromSignup
              ? 'Enter your company email id'
              : 'Enter your registered email address'
          }
        />
        {errorMsg && (
          <div className='my-1'>
            <InputErrorMsg text={errorMsg} />
          </div>
        )}

        <br />
        <Button
          disabled={loading || email.length === 0}
          type="submit"
          size="fullWidth"
        >
          {loading ? 'Loading...' : fromSignup ? 'Get Started' : 'Continue'}
        </Button>
      </form>

      {!fromSignup && (
        <>
          {allowedAuth?.linkedin && (
            <>
              <div className="my-5 flex items-center gap-3 text-zinc-500">
                <div className="h-[1px] flex-1 border"></div>
                <div className="text-xs">or login with</div>
                <div className="h-[1px] flex-1 border"></div>
              </div>
              <a
                href={
                  isTenantIdExistsOnSessionStorage
                    ? `${process.env.NEXT_PUBLIC_CW_AUTH_URL}/linkedin`
                    : '#'
                }
              >
                <Button variant="outline" size="fullWidth">
                  <IoLogoLinkedin className="mr-2 h-[15px] w-[15px]" /> Linkedin
                </Button>
              </a>{' '}
            </>
          )}

          <p className="mt-5 text-center text-sm font-medium text-zinc-500">
            {`Don't`} have an account?{' '}
            <Link
              className="text-primary-950"
              href={`/signup${tenantId ? `?tenantId=${tenantId}` : ''}`}
            >
              Signup Now
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default EmailForm;
