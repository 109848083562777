'use client';

import * as React from 'react';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';

import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';

import InputErrorMsg from './InputErrorMsg';

const OtpInput = ({ value, setValue, isError }: any) => {
  const slotArr = [0, 1, 2, 3, 4, 5];
  return (
    <div className="space-y-2">
      <InputOTP
        maxLength={6}
        value={value}
        onChange={(value) => setValue(value)}
        className="w-full"
        pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
        autoFocus
      >
        <InputOTPGroup className="flex w-full gap-[10px]">
          {slotArr.map((slot) => (
            <InputOTPSlot
              index={slot}
              key={slot}
              className={`w-full rounded-[6px] border ${isError ? 'border-red-500' : ''}`}
            />
          ))}
        </InputOTPGroup>
      </InputOTP>

      {isError && (
        <div>
          <InputErrorMsg text={isError} />
        </div>
      )}
    </div>
  );
};

export default OtpInput;
