import { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { otpFrom } from '@/utils/constants';
import toast from 'react-hot-toast';
import { FiArrowLeft } from 'react-icons/fi';

import useOtpSend from '@/hooks/useOtpSend';
import { Button } from '@/components/ui/button';
import OtpInput from '@/components/reUsables/OtpInput';

const OTPForm = ({
  loading,
  email,
  value,
  setValue,
  handleVerify,
  setIsEmailForm,
  url,
  fromSignup,
  tenantId,
  errorMsg,
  setErrorMsg,
  // handleRevertBackToEmailForm,
}: {
  loading: boolean;
  email: string;
  value: string;
  setValue: (value: string) => void;
  handleVerify: () => void;
  setIsEmailForm?: (isEmailForm: boolean) => void;
  url?: string;
  fromSignup?: boolean;
  tenantId: string;
  handleRevertBackToEmailForm?: () => void;
  errorMsg?: string;
  setErrorMsg: any;
}) => {
  const { loading: otpSendLoading, data, handleClick } = useOtpSend();
  const router = useRouter();

  useEffect(() => {
    if (data?.message) {
      setValue('');
      toast.success(data.message);
    }
  }, [data]);

  return (
    <div className="mt-8">
      <p className="text-sm text-zinc-500">
        Enter the verification OTP sent to{' '}
        <span className="text-zinc-950">{email} </span>
      </p>
      <div className="mt-4">
        <div className="mb-3 flex items-center justify-between">
          <p className="text-sm font-medium text-zinc-950">Verification code</p>
          {value && (
            <div
              className="cursor-pointer text-sm font-medium text-zinc-950"
              onClick={() => {
                setValue('');
                setErrorMsg('');
              }}
            >
              Clear
            </div>
          )}
        </div>

        <OtpInput value={value} setValue={setValue} isError={errorMsg} />

        <p className="mt-5 text-sm text-zinc-500">
          Didn’t receive OTP?{' '}
          <span
            onClick={() =>
              otpSendLoading
                ? () => console.log('loading...')
                : handleClick(email, tenantId, otpFrom.SIGNUP, fromSignup)
            }
            className={` font-medium ${otpSendLoading ? 'cursor-wait text-zinc-400' : 'cursor-pointer text-primary-950'} `}
          >
            {otpSendLoading ? 'Sending...' : 'Send again'}
          </span>{' '}
        </p>
        <br />
        <Button
          disabled={loading || otpSendLoading}
          onClick={handleVerify}
          type="button"
          size="fullWidth"
        >
          {loading ? 'Verifying...' : 'Verify OTP'}
        </Button>
      </div>
      {fromSignup ? (
        <>
          <p className="pt-6 text-center text-sm text-zinc-500">
            Have an account?{' '}
            <Link
              className="cursor-pointer font-medium text-primary-950"
              href="/"
            >
              Login
            </Link>
          </p>

          <div
            onClick={() => window.location.reload()}
            className="mt-6 flex cursor-pointer items-center justify-center gap-2 text-primary-950"
          >
            <FiArrowLeft className="h-5 w-5" />
            <p className="text-sm font-medium">Signup with another email</p>
          </div>
        </>
      ) : (
        <p
          onClick={() =>
            url ? router.push(url) : setIsEmailForm && setIsEmailForm(true)
          }
          className="cursor-pointer pt-6 text-center text-sm font-medium text-primary-950 hover:underline"
        >
          Login with another account
        </p>
      )}
    </div>
  );
};

export default OTPForm;
