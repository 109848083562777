import { useEffect, useState } from 'react';

const useSessionStorageInfo = () => {
  const [redirectURL, setRedirectURL] = useState('');
  const [postParent, setPostParent] = useState(false);
  const [clientId, setClientId] = useState('');
  const [tenantData, setTenantData] = useState({
    _id: '',
    tenantName: '',
    logo: '',
    linkedin: false,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const stringifyData = window.sessionStorage.getItem('tenant');
        const redirectURL = window.sessionStorage.getItem('redirectURL');
        const postParent = window.sessionStorage.getItem('postParent');
        const clientId = window.sessionStorage.getItem('clientId');
        console.log({ postParent });
        setClientId(clientId ? clientId : '');
        setPostParent(postParent != null);
        setRedirectURL(redirectURL ? redirectURL : '');
        setTenantData(
          stringifyData !== null ? JSON.parse(stringifyData) : null
        );
      } catch (error) {
        // Handle parsing error
        console.error('Error parsing tenant data:', error);
        // Optionally, you can clear sessionStorage if the data is corrupted
        // window.sessionStorage.removeItem('tenant');
      }
    }
  }, []);

  return {
    tenantData,
    redirectURL,
    postParent,
    setTenantData,
    clientId,
  };
};
export default useSessionStorageInfo;
