import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useOtpVerify = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string>('');

  const clientId =
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('clientId')
      : null;

  const handleClick = async (email: string, tenantId: string, otp: string) => {
    if (email && tenantId && otp && clientId) {
      setError('');
      setLoading(true);
      try {
        const url = process.env.NEXT_PUBLIC_API_URL;
        const res = await axios.post(`${url}/verify-otp`, {
          email,
          tenantId,
          otp,
          clientId,
        });
        if (res.data) {
          setData(res.data);
        }
      } catch (error: any) {
        const axiosError = error;
        if (axiosError?.response) {
          setError(axiosError?.response?.data?.message || 'An error occurred');
        } else if (axiosError?.request) {
          setError('Request failed, please try again later');
        } else {
          setError('An error occurred, please try again later');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Email, Tenant id, Client id and OTP must be specified');
    }
  };

  return {
    loading,
    handleClick,
    data,
    error,
  };
};

export default useOtpVerify;
