import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';

const useEmailCheck = ({
  REQUEST_FROM_SIGNUP,
}: {
  REQUEST_FROM_SIGNUP?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    email: string;
    signUpWith: string;
    isUserInSameTenant: boolean;
    isPasswordEnabled: boolean | undefined;
    isOtpEnable: boolean;
    shakey: string;
  } | null>(null);
  const [error, setError] = useState<string>('');

  const handleClick = async (email: string, tenantId: string) => {
    if (email) {
      setError('');
      setData(null);
      setLoading(true);
      try {
        const url = process.env.NEXT_PUBLIC_API_URL;
        const res = await axios.post(`${url}/email/check`, {
          email,
          tenantId,
          request_from_signup: !!REQUEST_FROM_SIGNUP,
        });
        if (res.data) {
          setData(res.data);
        }
      } catch (error: any) {
        const axiosError = error;
        if (axiosError?.response) {
          setError(axiosError?.response?.data?.message || 'An error occurred');
        } else if (axiosError?.request) {
          setError('Request failed, please try again later');
        } else {
          setError('An error occurred, please try again later');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Email required');
    }
  };

  return {
    loading,
    handleClick,
    data,
    error,
  };
};

export default useEmailCheck;
